var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"first-comment-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('multi-message-editor-with-media-input',{staticClass:"mb-0 mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'messages',
          'prefix': ((_vm.module.model.snakeCaseType) + "_"),
          'placeholders': _vm.BASE_PLACEHOLDERS,
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramBase,
          'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
          'settingsStructure': _vm.omit(_vm.defaultMessageEditorSettingsStructure, ['send_after']),
          'blacklistMediaButtonTypes': _vm.blacklistedFileButtonsByLicense,
          'mediaButtonsMessage': _vm.mediaButtonsLicenseMessage,
        },
      }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'reaction',
          'prefix': 'first_comment_',
          'options': _vm.emojiOptions,
          'hasAnchor': true,
          'tariffTags': _vm.getTagsByFieldKey('first_comment_reaction'),
          'settingWrapper': {
            'customiseValue': _vm.emojiOptions[0],
            'value': { value: null },
          },
        }
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }